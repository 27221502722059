import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

export interface ApiResponse {
  response: string;
  name: string;
  errorMessage: string;
  errorCode: string;
  status: string;
}

@Injectable({
  providedIn: 'root'
})
export class StatusService {
  private apiUrl = '/v1/api/status/check';
  private authToken: string | null = null;

  constructor(private http: HttpClient) {}

  fetchToken() : any {

    const headers = new HttpHeaders({
      'Accept': '*/*'
    });

    const credentials = {
      username: environment.IAM_USER,
      password: environment.IAM_PWD
    };

    this.http.post<{ access_token: string }>('/api/iam/authentification/login', credentials, { headers })
      .toPromise()
      .then(response => {
        this.authToken = response.access_token;
        sessionStorage.setItem("IAM_TOKEN", this.authToken);
        if (!this.authToken) {
          console.error('No authentication token available');
          return null;
        }else{
          return this.authToken;
        }

      })
      .catch(error => {
        console.error('Failed to fetch token', error);
      });
  }

  getApiStatus(authToken: string): Observable<ApiResponse[]> {
    const headers = new HttpHeaders({
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      });

    return this.http.get<ApiResponse[]>(this.apiUrl, { headers }).pipe(
      catchError(error => {
        console.error('API call failed:', error);
        let errorMessage = 'API Status ERROR : ';

        if (error.error instanceof HttpErrorResponse) {
          // Client-side or network error
          errorMessage += `Client-side error: ${error.error.message}`;
        } else {
          // Server-side error
          switch (error.status) {
            case 0:
              errorMessage += 'No connection. Verify your network and API server status.';
              break;
            case 400:
              errorMessage += `Bad Request: ${error.message}`;
              break;
            case 401:
              errorMessage += `Unauthorized: ${error.message}`;
              break;
            case 403:
              errorMessage += `Forbidden: ${error.message}`;
              break;
            case 404:
              errorMessage += `Not Found: ${error.message}`;
              break;
            case 500:
              errorMessage += `Internal Server Error: ${error.message}`;
              break;
            default:
              errorMessage += `Server-side error: ${error}`;
              break;
          }
        }
        Swal.fire({
          text:"message",
          html:'<div style="background-color: black; color: #d70000; font-size: 15px; border: solid; border-style: ridge; padding: 10px; text-align: left;">'+errorMessage+'</div>',
          confirmButtonColor: 'rgb(3, 142, 220)',
        });
        return throwError(error); // Rethrow the error for further handling if needed
      })
    );
    // return this.http.get<ApiResponse[]>(this.apiUrl, { headers });
  }
}
