import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Router } from "@angular/router";

@Injectable()
export class RequestsInterceptor implements HttpInterceptor {

  constructor(private ngxLoader: NgxUiLoaderService,
              private router: Router
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //Start the loader
    if(request.url != "assets/i18n/en.json") {
      this.ngxLoader.start();
      console.log(">>>>>>>< loading START", request.url);
    }
    let ok: string;
    let err: string;
    let retries = 0;
    const maxRetries = 3; // Maximum number of retries
    const started = Date.now();

    return next.handle(request)
      .pipe(
        tap({
          // Succeeds when there is a response; ignore other events
          next: (event) => (ok = event instanceof HttpResponse ? 'succeeded' : ''),
          // Operation failed; error is an HttpErrorResponse
          error: (error) => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
              //Here I want to retry the same request that got 401

            } else {
              // Handle other errors
              err = error.message;
            }
          }
        }),
        // Retry the request when it receives a 401 status code
        //retry(maxRetries),

        // Log when response observable either completes or errors
        finalize(() => {
          const elapsed = Date.now() - started;
          const msg = `${request.method} "${request.urlWithParams}"
              ${ok} in ${elapsed} ms.`;
          //console.log(msg);
          // if(!environment.production && err){
          //   let notification: Notification = {
          //     headline: 'Toast Headline',
          //     allowClose: true,
          //     autoHide: true,
          //     wait: 5000,
          //     message: err,
          //     type: NotificationTypes.error
          //   };
          //
          //   this.notificationService.show(notification);
          // }

          //Stop all loaders
          if(request.url != "assets/i18n/en.json") {
            this.ngxLoader.stopAll();
            console.log(">>>>>>>< loading STOP", request.url);
          }
        })
      );

  }
}

