export const environment = {
  production: true,
  IAM_USER:"lbv-app-svn",
  IAM_PWD:"LICertigib",
  defaultauth: 'firebase',
  firebaseConfig: {
    databaseURL: '',
    apiKey: "AIzaSyCPvof0VYynUHLjrxmo_ULlj-5maiRSiXI",
    authDomain: "api-check-c96d7.firebaseapp.com",
    projectId: "api-check-c96d7",
    storageBucket: "api-check-c96d7.appspot.com",
    messagingSenderId: "491381726863",
    appId: "1:491381726863:web:8fcbf68e8364a67b8fdafa"
  },
  baseUrl: "http://localhost:4200",
  keycloak: {
    issuer: 'https://keycloak-tg.lbv-security-rec.label-factory.ma',
    realm: 'digital-dashboard-api',
    clientId: 'digital-dashboard-api-client',
  }
};
