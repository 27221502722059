import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

interface ApiData {
  name: string;
  url: string;
  method: string;
  payload: string;
}
interface ApiResult {
  statusCode: number;
  response: string;
}
@Component({
  selector: 'app-direct-call',
  templateUrl: './direct-call.component.html',
  styleUrls: ['./direct-call.component.scss']
})

/**
 * Ecommerce Component
 */
export class DirectCallComponent implements OnInit {

  public basicHeatmapChart: any;
  private apis: ApiData[] = [];
  breadCrumbItems!: Array<{}>;
  private apiResults: { [key: string]: ApiResult } = {};
  private authToken: string | null = null;
  public magasins = [''];

  constructor(private http: HttpClient) {}

  
  ngOnInit(): void {

    this.authToken = sessionStorage.getItem("IAM_TOKEN");
    this.fetchApis();
  }
  
  fetchApis(): void {
    if (!this.authToken) {
      console.error('No authentication token available');
      return;
    }

    this.http.get<ApiData[]>('assets/API_LIST.json').subscribe(
      (data: ApiData[]) => {
        this.apis = data;
        this.callApis();
      },
      (error) => {
        console.error('Failed to load API data', error);
      }
    );
  }

  callApis(): void {
    
    const dataPromises = this.apis.map((api) => this.callApi(api));

    Promise.all(dataPromises).then((results) => {
      this.basicHeatmapChart = {
        series: results,
        chart: {
          height: 450,
          type: 'heatmap'
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: this.magasins
        },
        plotOptions: {
          heatmap: {
            colorScale: {
              ranges: [
                { from: 0, to: 0, color: '#ff0000', name: 'Failure' },
                { from: 1, to: 1, color: '#00ff00', name: 'Success' }
              ]
            }
          }
        },
        tooltip: {
          y: {
            formatter: (value: number, { seriesIndex, dataPointIndex, w }: any) => {
              const apiName = w.globals.seriesNames[seriesIndex];
              const magasin = w.globals.labels[dataPointIndex];
              const result = this.apiResults[`${apiName}-${magasin}`];
              if (result) {

                return `Status: ${result.statusCode}<br>Response: ${result.response}`;
              }
              return value === 1 ? 'Success' : 'Failure';
            }
          }
        },
        title: {
          text: 'API Calls Status'
        }
      };
    });
  }

  callApi(api: ApiData): Promise<{ name: string, data: number[] }> {
    if (!this.authToken) {
      return Promise.resolve({ name: api.name, data: [] });
    }
  
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.authToken}`,
      'Content-Type': 'application/json'
    });
  
    return new Promise((resolve) => {
      const apiCalls = this.magasins.map((magasin) => {
        // Determine the HTTP method and set up the appropriate request
        let request: Promise<any>;
        if (api.method === 'POST') {
          // POST request with payload
          request = this.http.post(api.url, api.payload, { headers, observe: 'response' }).toPromise();
        } else {
          // Default to GET request
          request = this.http.get(api.url, { headers, observe: 'response' }).toPromise();
        }
  
        // Make the API call
        return request.then(
          (response) => {
            const success = response.status === 200 ? 1 : 0;
            const result = {
              statusCode: response.status,
              response: JSON.stringify(response.body)
            };
            this.apiResults[`${api.name}-${magasin}`] = result;
            return success;
          },
          (error) => {
            const result = {
              statusCode: error.status || 0,
              response: error.message || 'Error'
            };
            this.apiResults[`${api.name}-${magasin}`] = result;
            return 0;
          }
        );
      });
  
      Promise.all(apiCalls).then((statuses) => {
        resolve({ name: api.name, data: statuses });
      });
    });
  }
  
}