import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  private authToken: string | null = null;

  constructor(private ngxService: NgxUiLoaderService,private http: HttpClient) {}

  async ngOnInit() {
    if(sessionStorage.getItem("IAM_TOKEN") == null ) await this.fetchToken();

  }

  fetchTokenOLD(): Promise<void> {

    const headers = new HttpHeaders({
      'Accept': '*/*'
    });

    const credentials = {
      username: environment.IAM_USER,
      password: environment.IAM_PWD
    };

    return this.http.post<{ access_token: string }>('/api/iam/authentification/login', credentials, { headers })
      .toPromise()
      .then(response => {
        this.authToken = response.access_token;
        sessionStorage.setItem("IAM_TOKEN", this.authToken);
      })
      .catch(error => {
        console.error('Failed to fetch token', error);
      });
  }



  private async fetchToken() {
    const headers = new HttpHeaders({
      'Accept': '*/*'
    });

    const credentials = {
      username: environment.IAM_USER,
      password: environment.IAM_PWD
    };
    try {
      const response = await this.http.post<{ access_token: string }>('/api/iam/authentification/login', credentials, { headers })
      .toPromise();

      // Assuming the token is in the response object, adjust accordingly
      this.authToken = response.access_token;
      sessionStorage.setItem('IAM_TOKEN', this.authToken);
    } catch (error) {
      console.error('Error fetching token:', error);
    }
  }
}