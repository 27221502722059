import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
// import { SessionStorageService } from '../../@core/sessions/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard {
  role: string[];
  error = '';
  constructor(
    protected override readonly router: Router,
    protected readonly keycloakService: KeycloakService,
    // protected session: SessionStorageService,  
  ) {
    super(router, keycloakService);
  }
  
  async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    
    if (!this.authenticated) {
      await this.keycloakService.login({
        redirectUri: '/auth/login',
      });
    }

    return this.authenticated;
  }



  public getRoleKyecloak(token: string): string[] {
    let jwtData = token.split('.')[1];

    let decodedJwtJsonData = window.atob(jwtData);
    let decodedJwtData = JSON.parse(decodedJwtJsonData);

    this.role = decodedJwtData.realm_access['roles'];
    return this.role;
  }

}