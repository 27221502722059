<!-- Start Breadcrumbs -->
<app-breadcrumbs title="APIs" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs>
<!-- End Breadcrumbs -->
<div *ngIf="basicHeatmapChart" class="row">
    <div class="col-xl-8">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title mb-0">API Caisse Status Check by Magasin :</h4>
        </div><!-- end card header -->
        <div class="card-body">
          <div class="live-preview">
            <apx-chart [series]="basicHeatmapChart.series" [chart]="basicHeatmapChart.chart"
                       [dataLabels]="basicHeatmapChart.dataLabels" [title]="basicHeatmapChart.title"
                       [plotOptions]="basicHeatmapChart.plotOptions" [xaxis]="basicHeatmapChart.xaxis"
                       [tooltip]="basicHeatmapChart.tooltip" dir="ltr">
            </apx-chart>
          </div>
        </div><!-- end card-body -->
      </div><!-- end card -->
    </div>
  </div>
  