<!-- start features -->
<section class="section">
    <div class="container">
        <div class="row align-items-center gy-4">
            <div class="col-lg-6 order-2 order-lg-1">
                <div class="text-muted">
                    <h5 class="fs-12 text-uppercase text-success">Design</h5>
                    <h4 class="mb-3">Well Designed Dashboards</h4>
                    <p class="mb-4 ff-secondary">Quality Dashboards (QD) is a condition-specific, actionable web-based application for quality reporting and population
                    management that is integrated into the Electronic Health Record (EHR).</p>

                    <div class="row">
                        <div class="col-sm-5">
                            <div class="vstack gap-2">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0 me-2">
                                        <div class="avatar-xs icon-effect">
                                            <div
                                                class="avatar-title bg-transparent text-success rounded-circle h2">
                                                <i class="ri-check-fill"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1">
                                        <h5 class="fs-14 mb-0">Ecommerce</h5>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0 me-2">
                                        <div class="avatar-xs icon-effect">
                                            <div
                                                class="avatar-title bg-transparent text-success rounded-circle h2">
                                                <i class="ri-check-fill"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1">
                                        <h5 class="fs-14 mb-0">Analytics</h5>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0 me-2">
                                        <div class="avatar-xs icon-effect">
                                            <div
                                                class="avatar-title bg-transparent text-success rounded-circle h2">
                                                <i class="ri-check-fill"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1">
                                        <h5 class="fs-14 mb-0">CRM</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-5">
                            <div class="vstack gap-2">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0 me-2">
                                        <div class="avatar-xs icon-effect">
                                            <div
                                                class="avatar-title bg-transparent text-success rounded-circle h2">
                                                <i class="ri-check-fill"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1">
                                        <h5 class="fs-14 mb-0">Crypto</h5>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0 me-2">
                                        <div class="avatar-xs icon-effect">
                                            <div
                                                class="avatar-title bg-transparent text-success rounded-circle h2">
                                                <i class="ri-check-fill"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1">
                                        <h5 class="fs-14 mb-0">Projects</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-4">
                        <a href="index.html" class="btn btn-primary">Learn More <i class="ri-arrow-right-line align-middle ms-1"></i></a>
                    </div>
                </div>
            </div>
            <!-- end col -->
            <div class="col-lg-6 col-sm-7 col-10 ms-auto order-1 order-lg-2">
                <div>
                    <img src="assets/images/landing/features/img-2.png" alt="" class="img-fluid">
                </div>
            </div>
        </div>
        <!-- end row -->

        <div class="row align-items-center mt-5 pt-lg-5 gy-4">
            <div class="col-lg-6 col-sm-7 col-10 mx-auto">
                <div>
                    <img src="assets/images/landing/features/img-3.png" alt="" class="img-fluid">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="text-muted ps-lg-5">
                    <h5 class="fs-12 text-uppercase text-success">structure</h5>
                    <h4 class="mb-3">Well Documented</h4>
                    <p class="mb-4">used to describe something that is known about or known to be true because there are many documents that describe it,
                    prove it, etc.</p>

                    <div class="vstack gap-2">
                        <div class="d-flex align-items-center">
                            <div class="flex-shrink-0 me-2">
                                <div class="avatar-xs icon-effect">
                                    <div class="avatar-title bg-transparent text-success rounded-circle h2">
                                        <i class="ri-check-fill"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <p class="mb-0">Dynamic Content</p>
                            </div>
                        </div>
                        <div class="d-flex align-items-center">
                            <div class="flex-shrink-0 me-2">
                                <div class="avatar-xs icon-effect">
                                    <div class="avatar-title bg-transparent text-success rounded-circle h2">
                                        <i class="ri-check-fill"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <p class="mb-0">Setup plugin's information.</p>
                            </div>
                        </div>
                        <div class="d-flex align-items-center">
                            <div class="flex-shrink-0 me-2">
                                <div class="avatar-xs icon-effect">
                                    <div class="avatar-title bg-transparent text-success rounded-circle h2">
                                        <i class="ri-check-fill"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <p class="mb-0">Themes customization information</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</section>
<!-- end features -->