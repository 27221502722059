import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

export type chartOptions = {
  series: any[];
  chart: any;
  dataLabels: any;
  xaxis: any;
  plotOptions: any;
  tooltip: any;
  title: any;
};

@Component({
  selector: 'app-caisse',
  templateUrl: './caisse.component.html',
  styleUrls: ['./caisse.component.scss']
})
export class CaisseComponent implements OnInit {
  public basicHeatmapChart: Partial<chartOptions>;

  public stores: any[] = [];
  private authToken: string | null;
  public magasins: string[] = [];
  public apiResults: { [key: string]: any } = {};
  breadCrumbItems!: Array<{}>;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.authToken = sessionStorage.getItem("IAM_TOKEN");
    this.getStoreStatus();
  }

  getStoreStatus(): void {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.authToken}`,
      'Content-Type': 'application/json'
    });
    this.http.get<any[]>('/v1/api/status/caisse', { headers }).subscribe(data => {
      this.magasins = data.map(store => store.libelleSite);
      const results = data.map(store => ({
        name: store.libelleSite,
        data: [{
          x: store.libelleSite,
          y: 1,  // Setting static value for Y-axis
          statusCode: store.status === 'OK' ? 200 : store.errorCode,
          response: store.response ? JSON.parse(store.response) : null,
          status: store.status === 'OK' ? 1 : 0
        }]
      }));

      results.forEach(result => {
        const storeName = result.name;
        this.apiResults[`${storeName}`] = result.data[0];
      });

      this.basicHeatmapChart = {
        series: [{
          name: "Status",
          data: results.map(store => ({ x: store.name, y: store.data[0].status }))
        }],
        chart: {
          height: 200,
          type: 'heatmap'
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: this.magasins
        },
        plotOptions: {
          heatmap: {
            colorScale: {
              ranges: [
                { from: 0, to: 0, color: '#ff0000', name: 'Failure' },
                { from: 1, to: 1, color: '#00ff00', name: 'Success' }
              ]
            }
          }
        },
        tooltip: {
          y: {
            formatter: (value: number, { seriesIndex, dataPointIndex, w }: any) => {
              const storeName = w.globals.labels[dataPointIndex];
              const result = this.apiResults[storeName];
              if (result) {
                return `Status: ${result.statusCode}<br>Response: ${result.response ? JSON.stringify(result.response) : 'No Response'}`;
              }
              return value === 1 ? 'Success' : 'Failure';
            }
          }
        },
        title: {
          text: 'API Calls Status'
        }
      };
    });
  }
}
