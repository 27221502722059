import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  
  {
    id: 1,
    label: 'Dashboard',
    icon: 'bx bxs-dashboard',
    link: '/',
    isCollapsed: false,
  },
  {
    id: 2,
    label: 'API Caisse',
    icon: 'bx bx-layer',
    isCollapsed: false,

    subItems: [
      {
        id: 1,
        label: 'Check by Magasin',
        link: '/caisse',
        parentId: 2
        
      },
      {
        id: 2,
        label: 'Logs',
        link: '/logs',
        parentId: 2
        
      },
      {
        id: 3,
        label: 'Order Summary Check',
        link: '/search',
        parentId: 1
      },


    ]
  },
  {
    id: 3,
    label: 'Diagnostique Globale',
    icon: 'bx bx-briefcase-alt',

    link: '/direct-call',
    parentId: 2
  },
];
