@for(data of UpcomingActivities; track $index){
<li class="list-group-item ps-0">
    <div class="row align-items-center g-3">
        <div class="col-auto">
            <div class="avatar-sm p-1 py-2 h-auto bg-light rounded-3">
                <div class="text-center">
                     <h5 class="mb-0">{{data.date}}</h5>
                     <div class="text-muted">{{data.day}}</div>
                </div>
            </div>
        </div>
        <div class="col">
            <h5 class="text-muted mt-0 mb-1 fs-12 fw-semibold">{{data.time}}</h5>
            <a href="javascript:void(0);" class="text-reset fs-14 mb-0">{{data.content}}</a>
        </div>
        <div class="col-sm-auto">
            <div class="avatar-group">
                @for(user of data.users; track $index){
                <div class="avatar-group-item">
                    @if(user.profile){
                    <a href="javascript: void(0);" class="d-block" ngbTooltip="{{user.name}}" placement="top">
                        <img :src="{{user.profile}}" alt="" class="rounded-circle avatar-xxs">
                    </a>
                    }@else{
                        <a href="javascript: void(0);" class="d-block">
                            <div class="avatar-xxs">
                                <span class="avatar-title rounded-circle {{user.variant}}">
                                    {{user.name?.charAt(0)}}
                                </span>
                            </div>
                        </a>
                    }
                </div>
                }
            </div>
        </div>
    </div>
    <!-- end row -->
</li><!-- end -->
}
