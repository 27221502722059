<div class="api-status-container">
  <h2>API Status Check</h2>
  <table class="api-status-table">
    <thead>
      <tr>
        <th>API Name</th>
        <th>Status</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let api of apiStatuses">
        <td>{{ api.name }}</td>
        <td [ngClass]="{'ok-status': api.status === 'OK', 'error-status': api.status !== 'OK'}">
          {{ api.status }}
          <span *ngIf="api.errorCode != '[object Object]'">{{ api.errorCode }}</span>
        </td>
        <td>
          <button *ngIf="!api.errorCode" type="button" class="btn btn-success btn-sm" id="sa-basic" (click)="basicMessage(api.response)">Show Response</button>
          <button *ngIf="api.errorCode" type="button" class="btn btn-danger btn-sm" id="sa-basic" (click)="errorMessage(api.errorMessage)">Show Error</button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
