import { KeycloakService } from "keycloak-angular";
import { environment } from "src/environments/environment";



export function initializeKeycloak(keycloak: KeycloakService): () => Promise<any> {
    return (): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          await keycloak.init({
            config: {
              url: environment.keycloak.issuer,
              realm: environment.keycloak.realm,
              clientId: environment.keycloak.clientId,
            },
            loadUserProfileAtStartUp: true,
            initOptions: {
              checkLoginIframe: false,
             // pkceMethod: 'S256',
              onLoad: 'login-required',
              
             // redirectUri: encodeURIComponent("/realms/nego/protocol/openid-connect/logout")
            },
            enableBearerInterceptor:false,
            bearerExcludedUrls: [
                '/auth/login'
              ]
          });
  
          resolve(resolve);
        } catch (error) {
          reject(error);
        }
      });
    };
  }
