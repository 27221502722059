<table class="table align-middle table-borderless table-centered table-nowrap mb-0">
    <thead class="text-muted table-light">
        <tr>
            <th scope="col" style="width: 62;">Active Page</th>
            <th scope="col">Active</th>
            <th scope="col">Users</th>
        </tr>
    </thead>
    <tbody>
        @for(data of TopPages; track $index){
        <tr>
            <td>
                <a href="javascript:void(0);">{{data.page}}</a>
            </td>
            <td>{{data.active}}</td>
            <td>{{data.users}}%</td>
        </tr><!-- end -->
    }
    </tbody><!-- end tbody -->
</table><!-- end table -->