<!-- ========== App Menu ========== -->
<div class="app-menu navbar-menu">
  <!-- LOGO -->
  <div class="navbar-brand-box">
    <!-- Dark Logo-->
    <a routerLink="/" class="logo logo-dark">
      <span class="logo-sm">
        <img src="assets/images/logo-sm.png" alt="" height="22">
      </span>
      <span class="logo-lg">
        <img src="assets/images/logo-dark.png" alt="" >
      </span>
    </a>
    <!-- Light Logo-->
    <a routerLink="/" class="logo logo-light">
      <span class="logo-sm">
        <img src="assets/images/logo-sm.png" alt="" height="22">
      </span>
      <span class="logo-lg">
        <img src="assets/images/logo-light.png" alt="" >
      </span>
    </a>
    <button type="button" class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover"
      (click)="toggleMobileMenu($event)">
      <i class="ri-record-circle-line"></i>
    </button>
  </div>

  <div id="scrollbar" class="h-100">
    <div class="container-fluid h-100">

      <div id="two-column-menu">
      </div>
      <ngx-simplebar class="sidebar-menu-scroll h-100">
        <ul class="metismenu list-unstyled navbar-nav" id="navbar-nav">
        @for(item of menuItems; track $index){
          @if(item.isTitle){
            <li class="menu-title"><span data-key="t-menu">{{ item.label | translate }}</span></li>
          }@else {
            <li class="nav-item">
              @if(hasItems(item)){
              <a href="javascript:void(0);" class="is-parent nav-link menu-link"
                [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}" data-bs-toggle="collapse"
                [attr.aria-expanded]="!item.isCollapsed" (click)="toggleItem(item)">
                <i class="{{ item.icon }} icon nav-icon" class=""></i>
                <span class=""> {{ item.label | translate }}</span>
               @if(item.badge){ <span class="badge badge-pill {{item.badge.variant}}">{{item.badge.text
                  | translate}}</span>}
              </a>

              <div class="collapse menu-dropdown" #collapse="ngbCollapse" [(ngbCollapse)]="item.isCollapsed">
                <ul class="nav nav-sm flex-column" aria-expanded="false">
                  @for(subitem of item.subItems; track $index){
                  <li class="nav-item">
                    @if(hasItems(subitem)){
                    <a class="nav-link" href="javascript:void(0);"
                      [attr.data-parent]="subitem.parentId" data-bs-toggle="collapse" [attr.aria-expanded]="!subitem.isCollapsed" (click)="toggleItem(subitem)">
                      {{ subitem.label | translate}}
                      @if(subitem.badge){<span class="badge badge-pill {{subitem.badge.variant}}">{{subitem.badge.text
                        | translate}}</span>}
                    </a>

                    <div class="collapse menu-dropdown sub-menu" #collapse="ngbCollapse" [(ngbCollapse)]="subitem.isCollapsed">
                      <ul class="nav nav-sm flex-column" aria-expanded="false">
                        @for(subSubitem of subitem.subItems; track $index){
                        <li class="nav-item">
                          @if(hasItems(subSubitem)){
                          <a class="nav-link" href="javascript:void(0);"
                            [attr.data-parent]="subSubitem.parentId" data-bs-toggle="collapse" [attr.aria-expanded]="!subSubitem.isCollapsed" (click)="toggleItem(subSubitem)">
                            {{ subSubitem.label | translate}}
                            @if(subSubitem.badge){ <span class="badge badge-pill {{subSubitem.badge.variant}}">{{subSubitem.badge.text
                                | translate}}</span>}
                          </a>
                            <div class="collapse menu-dropdown extra-sub-menu"  #collapse="ngbCollapse" [(ngbCollapse)]="subSubitem.isCollapsed">
                              <ul class="nav nav-sm flex-column" aria-expanded="false">
                                @for(subSubitem1 of subSubitem.subItems; track $index){
                                <li class="nav-item">
                                  <a [attr.data-parent]="subSubitem1.parentId" [routerLink]="subSubitem1.link"
                                    class="nav-link" (click)="updateActive($event)">
                                    {{ subSubitem1.label | translate }}
                                    @if(item.badge){<span class="badge badge-pill {{item.badge.variant}}">{{item.badge.text
                                      | translate}}</span>}
                                  </a>
                                </li>
                                }
                              </ul>
                            </div>
                          }@else{
                            <a [routerLink]="subSubitem.link" class="nav-link"
                            [attr.data-parent]="subSubitem.parentId" (click)="updateActive($event)">
                            {{ subSubitem.label | translate}}
                            @if(subSubitem.badge){<span class="badge badge-pill {{subSubitem.badge.variant}}">{{subSubitem.badge.text
                              | translate}}</span> }
                          </a>
                          }
                        </li>
                        }
                      </ul>
                    </div>
                    }@else{
                      <a [routerLink]="subitem.link" class="nav-link"
                      [attr.data-parent]="subitem.parentId" (click)="updateActive($event)">
                      {{ subitem.label | translate}}
                      @if(subitem.badge){ <span class="badge badge-pill {{subitem.badge.variant}}">{{subitem.badge.text
                        | translate}}</span> }
                    </a>
                  }
                  </li>
                  }
                </ul>
              </div>
            }@else{
              <a [routerLink]="item.link" class="side-nav-link-ref nav-link menu-link">
              @if(item.icon){<i class="bx {{ item.icon }} icon nav-icon" class=""></i> }
              <span class=""> {{ item.label | translate }}</span>
              @if(item.badge){ <span class="badge badge-pill {{item.badge.variant}}">{{item.badge.text
                | translate}}</span>}
            </a>
            }
            </li>
          }
        }
        </ul>
      </ngx-simplebar>
    </div>
    <!-- Sidebar -->
  </div>
  <div class="sidebar-background"></div>
</div>
<!-- Left Sidebar End -->
<!-- Vertical Overlay-->
<div class="vertical-overlay" (click)="SidebarHide()"></div>