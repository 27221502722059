@for(data of NewsFeed; track $index){
<div class="d-flex align-middle">
    <div class="flex-shrink-0">
        <img src="{{data.image}}" class="rounded img-fluid" style="height: 60px;" alt="">
    </div>
    <div class="flex-grow-1 ms-3">
        <h6 class="mb-1 lh-base"><a href="javascript:void(0);" class="text-reset">{{data.content}}</a></h6>
        <p class="text-muted fs-12 mb-0">{{data.date}} <i class="mdi mdi-circle-medium align-middle mx-1"></i>{{data.time}}</p>
    </div>
</div><!-- end -->
}