import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/core/services/api.service';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrl: './logs.component.scss'
})
export class LogsComponent {

  response: string[] = [];
  fullLog: string = '';
  error!: string;
  startIndex: number = 0;
  chunkSize: number = 5000; // Number of characters to display at a time
  isLoading: boolean = false;
  hasMoreLogs: boolean = true;
  
  constructor(private http: HttpClient, private apiService: ApiService) { }

  ngOnInit(): void {
    this.fetchLogs();

  }

  // fetchLogsOLD() {
  //   this.apiService.authenticate().pipe(
  //     switchMap(token => this.apiService.caisselogs(token))
  //   ).subscribe(
  //     (response: string) => {
  //       this.response = response;
  //       this.error = "";
  //     },
  //     (error: any) => {
  //       console.error('Error:', error);
  //       this.error = error.message || error;
  //       this.response = "";
  //     }
  //   );
  // }
  
  fetchLogs() {
    if (this.isLoading) return;

    this.isLoading = true;
    this.apiService.authenticate().pipe(
      switchMap(token => this.apiService.caisselogs(token))
    ).subscribe(
      (response: string) => {
        this.fullLog = response;
        this.loadMoreLogs();
        this.isLoading = false;
      },
      (error: any) => {
        console.error('Error:', error);
        this.error = error.message || error;
        this.isLoading = false;
      }
    );
  }

  loadMoreLogs() {
    const nextChunk = this.fullLog.slice(this.startIndex, this.startIndex + this.chunkSize);
    if (nextChunk) {
      this.response.push(nextChunk);
      this.startIndex += this.chunkSize;
      if (this.startIndex >= this.fullLog.length) {
        this.hasMoreLogs = false;
      }
    }
  }

  onLoadMore() {
    if (!this.isLoading && this.hasMoreLogs) {
      this.loadMoreLogs();
    }
  }

  onLoadAll() {
    if (!this.isLoading) {
      this.isLoading = true;
      const remainingChunk = this.fullLog.slice(this.startIndex);
      if (remainingChunk) {
        this.response.push(remainingChunk);
        this.startIndex = this.fullLog.length;
        this.hasMoreLogs = false;
      }
      this.isLoading = false;
    }
  }
}
