<!-- start team -->
<section class="section bg-light" id="team">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8">
                <div class="text-center mb-5">
                    <h3 class="mb-3 fw-semibold">Our <span class="text-danger">Team</span></h3>
                    <p class="text-muted mb-4 ff-secondary">To achieve this, it would be necessary to have uniform grammar,
                        pronunciation and more common words. If several languages coalesce the grammar.</p>
                </div>
            </div>
        </div>
        <!-- end row -->
        <div class="row">
            @for(data of Teams; track $index){
            <div class="col-lg-3 col-sm-6">
                <div class="card">
                    <div class="card-body text-center p-4">
                        <div class="avatar-xl mx-auto mb-4 position-relative">
                            <img src="{{data.image}}" alt="" class="img-fluid rounded-circle">
                            <a href="/mailbox"
                                class="btn btn-success btn-sm position-absolute bottom-0 end-0 rounded-circle avatar-xs">
                                <div class="avatar-title bg-transparent">
                                    <i class="ri-mail-fill align-bottom"></i>
                                </div>
                            </a>
                        </div>
                        <!-- end card body -->
                        <h5 class="mb-1"><a href="/pages/profile" class="text-body">{{data.name}}</a></h5>
                        <p class="text-muted mb-0 ff-secondary">{{data.position}}</p>
                    </div>
                </div>
                <!-- end card -->
            </div>
        }
        </div>
        <!-- end row -->
        <div class="row">
            <div class="col-lg-12">
                <div class="text-center mt-2">
                    <a href="/pages/team" class="btn btn-primary">View All Members <i
                            class="ri-arrow-right-line ms-1 align-bottom"></i></a>
                </div>
            </div>
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</section>
<!-- end team -->