const ClientLogo = [
  {
    id: '1',
    title: "Artwork",
    count: "206",
    images: [
      {
        image: [{ img: 'assets/images/nft/img-06.jpg' }, { img: 'https://img.themesbrand.com/velzon/images/img-2.gif' }]
      },
      {
        image: [{ img: 'https://img.themesbrand.com/velzon/images/img-5.gif' }, { img: 'assets/images/nft/img-03.jpg' }]
      },
    ],
  },
  {
    id: '2',
    title: "Crypto Card",
    count: "743",
    images: [
      {
        image: [{ img: 'assets/images/nft/img-05.jpg' }, { img: 'https://img.themesbrand.com/velzon/images/img-1.gif' }]
      },
      {
        image: [{ img: 'https://img.themesbrand.com/velzon/images/img-4.gif' }, { img: 'assets/images/nft/img-04.jpg' }]
      },
    ],
  },
  {
    id: '3',
    title: "Music",
    count: "679",
    images: [
      {
        image: [{ img: 'assets/images/nft/img-02.jpg' }, { img: 'https://img.themesbrand.com/velzon/images/img-3.gif' }]
      },
      {
        image: [{ img: 'https://img.themesbrand.com/velzon/images/img-1.gif' }, { img: 'assets/images/nft/img-01.jpg' }]
      },
    ],
  },
  {
    id: '4',
    title: "Games",
    count: "341",
    images: [
      {
        image: [{ img: 'assets/images/nft/img-03.jpg' }, { img: 'https://img.themesbrand.com/velzon/images/img-5.gif' }]
      },
      {
        image: [{ img: 'https://img.themesbrand.com/velzon/images/img-2.gif' }, { img: 'assets/images/nft/img-05.jpg' }]
      },
    ],
  },
  {
    id: '5',
    title: "Photography",
    count: "1452",
    images: [
      {
        image: [{ img: 'assets/images/nft/img-02.jpg' }, { img: 'https://img.themesbrand.com/velzon/images/img-3.gif' }]
      },
      {
        image: [{ img: 'https://img.themesbrand.com/velzon/images/img-1.gif' }, { img: 'assets/images/nft/img-01.jpg' }]
      },
    ],
  },
  {
    id: '6',
    title: "3d Style ",
    count: "4781",
    images: [
      {
        image: [{ img: 'assets/images/nft/img-05.jpg' }, { img: 'https://img.themesbrand.com/velzon/images/img-1.gif' }]
      },
      {
        image: [{ img: 'https://img.themesbrand.com/velzon/images/img-4.gif' }, { img: 'assets/images/nft/img-04.jpg' }]
      },
    ],
  },
  {
    id: '7',
    title: "Collectibles",
    count: "3468",
    images: [
      {
        image: [{ img: 'assets/images/nft/img-06.jpg' }, { img: 'https://img.themesbrand.com/velzon/images/img-2.gif' }]
      },
      {
        image: [{ img: 'https://img.themesbrand.com/velzon/images/img-5.gif' }, { img: 'assets/images/nft/img-03.jpg' }]
      },
    ],
  },
  {
    id: '8',
    title: "Videos",
    count: "1674",
    images: [
      {
        image: [{ img: 'assets/images/nft/img-03.jpg' }, { img: 'https://img.themesbrand.com/velzon/images/img-5.gif' }]
      },
      {
        image: [{ img: 'https://img.themesbrand.com/velzon/images/img-2.gif' }, { img: 'assets/images/nft/img-05.jpg' }]
      },
    ],
  },
];

export { ClientLogo };
