import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ApiService } from 'src/app/core/services/api.service';
import { switchMap } from 'rxjs/operators';
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss'
})
export class SearchComponent implements OnInit{

  country: string = '';
  keyword: string = '';
  mediaType: string = '';
  active: boolean = true;
  fromDate: string = '';
  toDate: string = '';
  searchForm!: UntypedFormGroup;

  response!: string ;
  error!: string ;
  
  constructor(private formBuilder: UntypedFormBuilder, private http: HttpClient, private apiService: ApiService) { }
  get f() { return this.searchForm.controls; }

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      codeMagasin: ['500'], // Example validation
      dateTransaction: ['01-07-2024']
    });
  }

  onSubmit() {
    const codeMagasin = this.searchForm.get('codeMagasin')!.value;
    const dateTransaction = this.searchForm.get('dateTransaction')!.value;
    const dateObj = new Date(dateTransaction);

    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = dateObj.getFullYear();
    
    const formattedDate = `${day}-${month}-${year}`;
    this.apiService.authenticate().pipe(
      switchMap(token => this.apiService.descenteCommande(token, codeMagasin, formattedDate))
    ).subscribe(response => {
      this.response = response;
      this.error = "";

    }, error => {
      console.error('Error:', error);
      this.error = error;
      this.response = "";
    });
  }
  


}
