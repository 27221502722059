<h4>API Caisse : Logs</h4>
<br />
<br />
<div *ngIf="response.length > 0">
  <h5>Réponse :</h5>
  <div style="background-color: black;">
    <div style=" font-family: monospace;
    white-space: pre;
    padding: 5px;
    border: 0px solid #ccc;
    overflow: auto;color: greenyellow; font-size: medium;" *ngFor="let log of response">{{ log }}</div>
  </div>
  <button *ngIf="hasMoreLogs" (click)="onLoadMore()" class="btn btn-primary" style="margin: 5px;">Load More</button>
  <button *ngIf="hasMoreLogs" (click)="onLoadAll()" class="btn btn-secondary" style="margin: 5px;">Load All</button>
</div>
<div *ngIf="error">
  <h5>Réponse :</h5>
  <div style="background-color: white;">
    <pre style="color: red; font-size: medium;">{{ error }}</pre>
  </div>
</div>
