import { Injectable } from '@angular/core';
import { getFirebaseBackend } from '../../authUtils';
import { User } from 'src/app/store/Authentication/auth.models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { GlobalComponent } from "../../global-component";
import { getAuth } from 'firebase/auth';
import { Auth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

const AUTH_API = GlobalComponent.AUTH_API;

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

@Injectable({ providedIn: 'root' })

/**
 * Auth-service Component
 */
export class AuthenticationService {
    auth: Auth;
    user!: User;
    currentUserValue: any;
    private currentUserSubject: BehaviorSubject<User>;

    constructor(private http: HttpClient, private store: Store,private router: Router,private readonly keycloak: KeycloakService) { 
        this.auth = getAuth();
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')!));
    }

    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    register(email: string, first_name: string, password: string) {
        return getFirebaseBackend()!.registerUser(email, password).then((response: any) => {
            const user:any = response;
            return user;
        });


    }


    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string){
        return getFirebaseBackend()!.loginUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });

    }

    /**
     * Returns the current user
     */
    public currentUser(): any {
        return getFirebaseBackend()!.getAuthenticatedUser();
    }


    logoutOLD() {
        getFirebaseBackend()!.logout().then(() => {
          sessionStorage.removeItem('currentUser');
          sessionStorage.removeItem('token');
        //   this.currentUserSubject.next(null!);
          this.router.navigate(['/auth/login']);
        }).catch(error => {
          console.error("Error during logout:", error);
        });
      }

      logout() {
        this.keycloak.logout();
        // sessionStorage.clear()
        // this.keycloak.clearToken();
        // this.keycloak.getKeycloakInstance().clearToken();

      }

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        return getFirebaseBackend()!.forgetPassword(email).then((response: any) => {
            const message = response.data;
            return message;
        });
    }

    googleLogin() {
        const provider = new GoogleAuthProvider();
        return signInWithPopup(this.auth, provider);
      }
}

