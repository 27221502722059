 <!-- start marketplace -->
 <section class="section bg-light" id="marketplace">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8">
                <div class="text-center mb-5">
                    <h2 class="mb-3 fw-bold lh-base">Explore Products</h2>
                    <p class="text-muted mb-4">Collection widgets specialize in displaying many elements of the same type, such as a collection of pictures from a collection of articles.</p>
                    <ul class="nav nav-pills filter-btns justify-content-center" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link fw-medium active" type="button" data-filter="all" (click)="activeCategory('allItems')" [ngClass]="{'active': galleryFilter ==='allItems'}">All Items</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link fw-medium" type="button" data-filter="artwork" (click)="activeCategory('artWork')" [ngClass]="{'active': galleryFilter ==='artWork'}">Artwork</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link fw-medium" type="button" data-filter="music" (click)="activeCategory('music')" [ngClass]="{'active': galleryFilter ==='music'}">Music</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link fw-medium" type="button" data-filter="games" (click)="activeCategory('games')" [ngClass]="{'active': galleryFilter ==='games'}">Games</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link fw-medium" type="button" data-filter="crypto-card" (click)="activeCategory('crypto-card')" [ngClass]="{'active': galleryFilter ==='crypto-card'}">Crypto Card</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link fw-medium" type="button" data-filter="3d-style" (click)="activeCategory('3d-style')" [ngClass]="{'active': galleryFilter ==='3d-style'}">3d Style</button>
                        </li>
                    </ul>
                </div>
            </div><!-- end col -->
        </div><!-- end row -->
        <div class="row">
            @for(data of filterredImages; track $index){
            <div class="col-lg-4 product-item artwork crypto-card 3d-style">
                <div class="card explore-box card-animate">
                    <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                        <button type="button" class="btn btn-icon active" data-bs-toggle="button" aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                    </div>
                    <div class="explore-place-bid-img">
                        <img src="{{data.image}}" alt="" class="card-img-top explore-img" />
                        <div class="bg-overlay"></div>
                        <div class="place-bid-btn">
                            <a href="javascript:void(0);" class="btn btn-success"><i class="ri-auction-fill align-bottom me-1"></i> Place Bid</a>
                        </div>
                    </div>
                    <div class="card-body">
                        <p class="fw-medium mb-0 float-end"><i class="mdi mdi-heart text-danger align-middle"></i> {{data.like}}k </p>
                        <h5 class="mb-1"><a href="/nft-details">{{data.title}}</a></h5>
                        <p class="text-muted mb-0">{{data.sub_title}}</p>
                    </div>
                    <div class="card-footer border-top border-top-dashed">
                        <div class="d-flex align-items-center">
                            <div class="flex-grow-1 fs-14">
                                <i class="ri-price-tag-3-fill text-warning align-bottom me-1"></i> Highest: <span class="fw-medium">{{data.highest}}ETH</span>
                            </div>
                            <h5 class="flex-shrink-0 fs-14 text-primary mb-0">{{data.eth}} ETH</h5>
                        </div>
                    </div>
                </div>
            </div>
        }
        </div>
    </div><!-- end container -->
</section>
<!-- end marketplace -->