import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

interface AuthResponse {
  access_token: string;
  }
  
  @Injectable({
    providedIn: 'root'
  })
  export class ApiService {
    private iamUrl = '/api/iam/authentification/login';
    private apiIAMUrl = '/v1/ventes-assistees/recapilatif-ventes';
    private apiCaisseUrl = '/logs';

  
    constructor(private http: HttpClient) { }
  
    authenticate(): Observable<string> {
      const payload = {
        username: environment.IAM_USER,
        password: environment.IAM_PWD
      };
  
      return this.http.post<AuthResponse>(this.iamUrl, payload).pipe(
        switchMap(response => {
          // Explicitly return the token as a string
          return new Observable<string>(observer => {
            observer.next(response.access_token);
            observer.complete();
          });
        })
      );
    }
  
    descenteCommande(bearerToken: string, codeMagasin: string, dateTransaction: string): Observable<any> {
      const headers = new HttpHeaders().set('Authorization', `Bearer ${bearerToken}`);
      const params = { codeMagasin, dateTransaction };
  
      return this.http.get(this.apiIAMUrl, { headers, params });
    }

    // caisselogs(token: string): Observable<string> {
    //   const url = this.apiCaisseUrl;
    //   const headers = new HttpHeaders({
    //     'Authorization': `Bearer ${token}`
    //   });
    //   return this.http.get(url, { headers, responseType: 'text' }).pipe(
    //     map(response => response as string)
    //   );
    // }
    caisselogs(token: string): Observable<string> {
      const url = this.apiCaisseUrl;
      const headers = new HttpHeaders({
        'Authorization': `Bearer ${token}`
      });
      return this.http.get(url, { headers, responseType: 'text' }).pipe(
        map(response => response as string)
      );
    }
    
    caisselogsOLD(bearerToken: string): Observable<any> {
      const headers = new HttpHeaders().set('Authorization', `Bearer ${bearerToken}`);
      const params = { };
  
      return this.http.get(this.apiCaisseUrl, { headers, params });
    }
  }
  
