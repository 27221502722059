import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiResponse, StatusService } from 'src/app/core/services/status.service';
import Swal from 'sweetalert2';

interface ApiData {
  name: string;
  url: string;
  method: string;
  payload: string;
}
interface ApiResult {
  statusCode: number;
  response: string;
}
@Component({
  selector: 'app-dashboard2',
  templateUrl: './dashboard2.component.html',
  styleUrls: ['./dashboard2.component.scss']
})

/**
 * Ecommerce Component
 */
export class Dashboard2Component implements OnInit {
  hoveredApi: ApiResponse | null = null;
  apiStatuses: ApiResponse[] = [];
  breadCrumbItems!: Array<{}>;
  selectedApiResponse: string | null = null;
  private authToken: string | null = null;

  
  constructor(private http: HttpClient, private apiStatusService: StatusService,private modalService: NgbModal) {}

  ngOnInit(): void {
    this.waitForToken().then(() => {
      this.authToken = sessionStorage.getItem("IAM_TOKEN");
      this.apiStatusService.getApiStatus(this.authToken).subscribe(
        (data) => {
          this.apiStatuses = data;
        },
        (error) => {
          console.error('Error fetching API status:', error);
        }
      );
    });

    // this.authToken = sessionStorage.getItem("IAM_TOKEN");
    // console.log(">>>>> this.authToken ", this.authToken );
    // this.apiStatusService.getApiStatus(this.authToken).subscribe(
    //   (data) => {
    //     this.apiStatuses = data;
    //   },
    //   (error) => {
    //     console.error('Error fetching API status:', error);
    //   }
    //   );
    
  }

  private waitForToken(): Promise<void> {

    return new Promise((resolve) => {
      const checkToken = () => {
        if (sessionStorage.getItem("IAM_TOKEN") !== null) {
          resolve();
        } else {
          console.log(">>>>> waiting for token ...");
          setTimeout(checkToken, 100); // Check again after 100ms
        }
      };
      checkToken();
    });
  }

  showResponseModal(response: string): void {
    this.selectedApiResponse = response;
  }

  closeModal(): void {
    this.selectedApiResponse = null;
  }

    /**
   * Basic sweet alert
   * @param basicMessage modal content
   */
  basicMessage(message) {
    Swal.fire({
      text:message,
      html:'<div style="background-color: black; color: #00e900; font-size: 15px; border: solid; border-style: ridge; padding: 10px; text-align: left;">'+message+'</div>',
      confirmButtonColor: 'rgb(3, 142, 220)',
    });
  }


  errorMessage(message) {
    Swal.fire({
      text:message,
      html:'<div style="background-color: black; color: #d70000; font-size: 15px; border: solid; border-style: ridge; padding: 10px; text-align: left;">'+message+'</div>',
      confirmButtonColor: 'rgb(3, 142, 220)',
    });
  }
}