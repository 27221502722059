import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { Dashboard2Component } from './dashboards/dashboard2/dashboard2.component';
import { DirectCallComponent } from './direct-call/direct-call.component';
import { CaisseComponent } from '../caisse/caisse.component';
import { SearchComponent } from './search/search.component';
import { LogsComponent } from '../logs/logs.component';

const routes: Routes = [
    {
        path: "",
        component: Dashboard2Component
    },
    {
      path: '', loadChildren: () => import('./dashboards/dashboards.module').then(m => m.DashboardsModule)
    },
    {
      path:'direct-call',
      component: DirectCallComponent
   },
    {
        path:'search',
        component: SearchComponent
    },
    {
      path:'caisse',
      component: CaisseComponent
  },
  {
    path:'logs',
    component: LogsComponent
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
