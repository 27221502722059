<h4>API Caisse : Récapituatif des commandes</h4>
<br/>
<form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="row gy-2 gx-3 mb-3 align-items-center">



        <div class="col-sm-1">
            <p>Magasin : </p>
            <label class="visually-hidden" for="autoSizingInput">Code Magasin</label>
            <input   formControlName="codeMagasin" type="text" class="form-control" id="autoSizingInput" >
        </div>

        <div class="col-lg-3">
            <div>
                <p>Date Transaction : </p>
                <input formControlName="dateTransaction" class="form-control flatpickr-input" type="text" mwlFlatpickr [altInput]="true" 
                [enableTime]="false" [convertModelValue]="true" altFormat="d-m-Y" [dateFormat]="'d-m-Y'">
            </div>
        </div>
        <div class="col-lg-2 ">
            <p>&nbsp; </p>
            <div class="col-auto">
                <button type="submit" class="btn btn-primary">Check</button>
            </div><!--end col-->
        </div>
    </div>
</form>
<br/>
<div *ngIf="response">
<h5>Réponse :</h5>
<div style="background-color: black;"><pre style="color: greenyellow; font-size: medium;">{{ response | json }}</pre></div>
</div>
<div *ngIf="error">
<h5>Réponse :</h5>
<div  style="background-color: white;"><pre style="color: red; font-size: medium;">{{ error | json }}</pre></div>
</div>